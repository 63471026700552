import { useMutation } from "@tanstack/react-query"
import { Modal } from "antd"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import { Dayjs } from "dayjs"
import api from "helpers/api"
import { isDefined, isSomeDefined } from "helpers/checkType"
import { useNotifications } from "hooks/useNotifications"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "store/types"
import { Uuid } from "types"
import { useCostCollectorStore } from "../CostCollector.store"
import { useCostCollectorAttributeStore } from "./CostCollectorAttributes.store"
import { AttributeValuePriceItem } from "./CostCollectorAttributes.types"

type PostPriceItem = {
    model: Uuid
    currency?: string
    attributeValue: Uuid
    region: string
    value?: number
    marginValue?: number
    marginFirst?: boolean
    actionExplanation: string
    startAt: string | null
}

type PostPriceData = {
    payload: PostPriceItem[]
}

type NotSetData = {
    reason: "attribute" | "value"
    model: string
    value: string
}

const getMarginFirstString = (v: boolean) => {
    return v ? "Да" : "Нет"
}

export const CostCollectorChangeCell = ({
    oldValue,
    newValue,
}: {
    oldValue?: number
    newValue?: number
}) => {
    if (!oldValue && typeof newValue === "undefined") return null

    return typeof newValue === "undefined" ? (
        <span style={{ fontWeight: "bold" }}>{oldValue}</span>
    ) : (
        <span>
            <s
                style={{
                    marginRight: 10,
                    fontWeight: "bold",
                }}
            >
                {oldValue}
            </s>
            {newValue ?? oldValue}
        </span>
    )
}

export default function CostCollectorAttributesSaveModal({
    refetch,
    hide,
    visible,
    date,
}: {
    refetch: (clearCacheFor: object[]) => void
    hide: () => void
    visible: boolean
    date: Dayjs | null
}) {
    const { selectedRows } = useCostCollectorStore()

    const activeRow = selectedRows[0]

    const { attributeValuesTableData, resetTableData, availableData } =
        useCostCollectorAttributeStore()

    const { showNotification } = useNotifications()

    const currentRegion = useSelector((state: RootState) => state.region.region)

    const tableData = useMemo(() => {
        return Object.values(
            Object.entries(attributeValuesTableData).reduce(
                (acc, [attributeId, data]) => {
                    const filteredData = data
                        .filter(
                            (el) =>
                                el.newPrice !== el.oldPrice ||
                                (el.newMarginFirst !== undefined &&
                                    el.newMarginFirst !== el.marginFirst) ||
                                el.newMarginValue !== el.marginValue
                        )
                        .filter((el) =>
                            isSomeDefined(
                                el.newMarginFirst,
                                el.newMarginValue,
                                el.newPrice
                            )
                        )

                    filteredData.forEach((item) => {
                        acc[`${item.model.id}_${item.attributeId}_${item.id}`] =
                            item
                    })

                    return acc
                },
                {} as Record<string, AttributeValuePriceItem>
            )
        )
    }, [attributeValuesTableData])

    const notSetData: NotSetData[] = useMemo(() => {
        const data: NotSetData[] = []
        if (!tableData || !availableData) return data
        tableData.forEach((el) => {
            const {
                model: { name: modelName },
                attributeId,
                attributeName,
                id,
                name,
            } = el

            Object.values(availableData).forEach((available) => {
                const attribute = available.attributes?.includes(attributeId)
                if (!attribute) {
                    data.push({
                        reason: "attribute",
                        model: available.modelName,
                        value: attributeName,
                    })
                } else {
                    const value = available.values?.includes(id)
                    if (!value) {
                        data.push({
                            reason: "value",
                            model: available.modelName,
                            value: name,
                        })
                    }
                }
            })
        })

        const uniqueData = Object.values(
            data.reduce((acc, item) => {
                const key = `${item.model}-${item.value}-${item.reason}`
                acc[key] = item
                return acc
            }, {} as Record<string, NotSetData>)
        )

        return uniqueData
    }, [tableData, availableData])

    const { mutate, isLoading } = useMutation({
        mutationKey: [date, tableData, currentRegion, activeRow],
        mutationFn: async () => {
            return api
                .post<PostPriceData, any>(
                    "v1/economics/attribute/prices",
                    {},
                    {
                        payload: tableData.map((el) => ({
                            model: el.model!.id,
                            region: currentRegion!.value,
                            value: isDefined(el.newPrice)
                                ? el.newPrice
                                : el.oldPrice,
                            actionExplanation: "",
                            startAt: date?.format("YYYY-MM-DD") ?? null,
                            attributeValue: el.id,
                            marginFirst:
                                typeof el.newMarginFirst === "boolean"
                                    ? el.newMarginFirst
                                    : el.marginFirst,
                            marginValue: isDefined(el.newMarginValue)
                                ? el.newMarginValue
                                : el.marginValue,
                            currency: activeRow?.currency?.id,
                        })),
                    }
                )
                .then(() => {
                    showNotification({
                        message: "Сохранено",
                        type: "success",
                    })
                    resetTableData()
                    refetch(
                        tableData.map((el) => ({
                            model: el.model!.id,
                            region: currentRegion!.value,
                        }))
                    )
                    hide()
                })
        },
        onError: (error: any) => {
            showNotification({
                message: error.message,
                type: "danger",
            })
        },
    })

    return (
        <>
            <Modal
                open={visible}
                width={"calc(100vw - 200px"}
                style={{ maxWidth: 1200 }}
                onCancel={hide}
                onOk={() => mutate()}
                okButtonProps={{
                    loading: isLoading,
                    className: "fw",
                }}
                okText="Добавить изменение цен на указанные значения аттрибута"
                title={"Просмотр цен"}
                cancelButtonProps={{
                    hidden: true,
                }}
            >
                <Flex.Col gap={10}>
                    <Flex.Row gap={10} className="fw" justify="start">
                        <span>Дата установки цен -</span>
                        <span style={{ fontWeight: "bold" }}>
                            {date?.format("YYYY-MM-DD")}
                        </span>
                    </Flex.Row>
                    <Flex.Row gap={10} className="fw" justify="start">
                        <span>Валюта - </span>
                        <span style={{ fontWeight: "bold" }}>
                            {activeRow?.currency?.name ?? ""}
                        </span>
                    </Flex.Row>
                    <UTable
                        data={tableData}
                        columns={[
                            {
                                columnName: "Аттрибут",
                                render: (row) => row.attributeName,
                                width: 10,
                            },
                            {
                                columnName: "Значение",
                                render: (row) => row.name,
                                width: 20,
                            },
                            {
                                columnName: "Модель",
                                render: (row) => row.model.name,
                            },
                            {
                                columnName: "Цена",
                                render: (row) => {
                                    return (
                                        <CostCollectorChangeCell
                                            newValue={row.newPrice}
                                            oldValue={row.oldPrice}
                                        />
                                    )
                                },
                                width: 20,
                            },
                            {
                                columnName: "Процент",
                                render: (row) => (
                                    <CostCollectorChangeCell
                                        newValue={row.newMarginValue}
                                        oldValue={row.marginValue}
                                    />
                                ),
                                width: 20,
                            },
                            {
                                columnName: "Сначала процент",
                                width: 15,
                                render: (row) => {
                                    return typeof row.newMarginFirst !==
                                        "undefined" &&
                                        row.marginFirst !==
                                            row.newMarginFirst ? (
                                        <span>
                                            <s
                                                style={{
                                                    marginRight: 10,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {getMarginFirstString(
                                                    row.marginFirst
                                                )}
                                            </s>
                                            {getMarginFirstString(
                                                row.newMarginFirst
                                            )}
                                        </span>
                                    ) : (
                                        getMarginFirstString(row.marginFirst)
                                    )
                                },
                            },
                        ]}
                    />

                    {!!notSetData.length && (
                        <UTable
                            data={notSetData}
                            columns={[
                                {
                                    width: 100,
                                    columnName: () => <></>,
                                    render: (row) => (
                                        <span>
                                            У модели <b>{row.model}</b>{" "}
                                            отсутствует
                                            {row.reason === "attribute"
                                                ? " выбранный аттрибут"
                                                : " выбранное значение"}{" "}
                                            <b>{row.value}</b>
                                        </span>
                                    ),
                                },
                            ]}
                        />
                    )}
                </Flex.Col>
            </Modal>
        </>
    )
}
