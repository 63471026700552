import React, { useMemo } from "react"
import { Uuid } from "types"
import { PicturesGeneratorParams } from "./index"
import { PicturesGeneratorPartAttributes } from "./PicturesGeneratorPartAttributes"

export const PicturesGeneratorContainersWrapper: React.FC<{
    params: PicturesGeneratorParams
    filledAttributes: {
        [attributeId: Uuid]: string
    }
    setFilledAttributes: (v: { [attributeId: Uuid]: string }) => void
    changeIsInterior: (val: boolean) => void
    isInterior: boolean
}> = ({
    params,
    filledAttributes,
    setFilledAttributes,
    changeIsInterior,
    isInterior,
}) => {
    useMemo(() => setFilledAttributes({}), [params])

    const handleFillAttribute = (attributeId: Uuid, valueId?: Uuid) => {
        let curr = filledAttributes

        if (!valueId) {
            curr = Object.keys(curr).reduce((prev, currKey) => {
                if (currKey === attributeId.toString()) {
                    return prev
                }
                return {
                    ...prev,
                    [currKey]: curr[currKey],
                }
            }, {})
        } else {
            curr = {
                ...curr,
                [attributeId]: valueId,
            }
        }

        setFilledAttributes(curr)
    }

    const resetPreviews = () => {
        setFilledAttributes({})
    }

    return (
        <>
            <PicturesGeneratorPartAttributes
                target={params.model ? "model" : "series"}
                onFillAttribute={handleFillAttribute}
                filled={filledAttributes}
                params={params}
                resetPreviews={resetPreviews}
                changeIsInterior={changeIsInterior}
                isInterior={isInterior}
            />
        </>
    )
}
