import buildUrl from "build-url-ts"
import { AsyncSelect } from "components/UI"
import React, { useMemo, useState } from "react"
import Sticky from "react-sticky-el"
import { Uuid } from "types"
import { PicturesGeneratorContainersWrapper } from "./PicturesGeneratorContainersWrapper"

export interface PicturesGeneratorParams {
    producer: Uuid
    group: Uuid
    series: Uuid
    model?: {
        label: string
        value: Uuid
    }
    groupName: string
}

export const PicturesGeneratorDemo: React.FC = () => {
    document.title = "По слоям"

    const [activeProducer, setActiveProducer] = useState<
        | {
              label: string
              value: Uuid
          }
        | undefined
    >(undefined)
    const [activeGroup, setActiveGroup] = useState<
        | {
              label: string
              value: Uuid
          }
        | undefined
    >(undefined)
    const [activeSeries, setActiveSeries] = useState<
        | {
              label: string
              value: Uuid
          }
        | undefined
    >(undefined)
    const [activeModel, setActiveModel] = useState<
        | {
              label: string
              value: Uuid
          }
        | undefined
    >()
    const [isInterior, setIsInterior] = useState(false)

    const [activeParams, setActiveParams] = useState<
        PicturesGeneratorParams | undefined
    >()

    const [filledAttributes, setFilledAttributes] = useState<{
        [attributeId: Uuid]: string
    }>({})

    const handleApplyActiveParams = () => {
        setActiveParams({
            producer: activeProducer!.value,
            group: activeGroup!.value,
            model: activeModel!,
            series: activeSeries!.value,
            groupName: activeGroup!.label,
        })
    }

    const pictureLink = useMemo(() => {
        if (!activeParams || Object.keys(filledAttributes).length === 0)
            return ""

        return buildUrl("/api/v1/pictures-generator/build", {
            queryParams: {
                producer: activeParams.producer,
                group: activeParams.group,
                model: activeParams.model?.value,
                series: activeParams.series,
                payload: btoa(JSON.stringify(filledAttributes)),
                v: Date.now(),
                interior: String(isInterior),
            },
        })
    }, [activeParams, filledAttributes])

    const isConfigureModel: boolean | null = useMemo(() => {
        if (
            !activeGroup?.value ||
            !activeProducer?.value ||
            !activeSeries?.value
        ) {
            return null
        }

        return !!activeModel?.value
    }, [activeSeries, activeGroup, activeProducer, activeModel])

    return (
        <>
            <h1 className="h3 mb-3">Создаватель визуальных дверей</h1>
            <div className="control">
                <div className="tab-content" id="nav-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="edit-settings"
                        role="tabpanel"
                        aria-labelledby="edit-settings-tab"
                    >
                        <div
                            className="control-view"
                            style={{
                                justifyContent: "stretch",
                            }}
                        >
                            <div className="control-view__main">
                                <div className="control-in">
                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Номенклатурная группа
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                onChange={(e) => {
                                                    setActiveProducer(undefined)
                                                    setActiveSeries(undefined)
                                                    setActiveModel(undefined)
                                                    setActiveGroup(
                                                        e as typeof activeGroup
                                                    )
                                                }}
                                                dataUrl="items-group"
                                                page={1}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Поставщик
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                isDisabled={!activeGroup?.value}
                                                onChange={(e) => {
                                                    setActiveSeries(undefined)
                                                    setActiveModel(undefined)
                                                    setActiveProducer(
                                                        e as typeof activeProducer
                                                    )
                                                }}
                                                dataUrl="producer"
                                                params={{
                                                    itemsGroups:
                                                        activeGroup?.value,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Серия
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                isDisabled={
                                                    !activeGroup?.value ||
                                                    !activeProducer?.value
                                                }
                                                onChange={(e) => {
                                                    setActiveSeries(
                                                        e as typeof activeSeries
                                                    )
                                                    setActiveModel(undefined)
                                                }}
                                                dataUrl="series"
                                                params={{
                                                    producer:
                                                        activeProducer?.value,
                                                    itemsGroup:
                                                        activeGroup?.value,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Модель
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                placeholder={
                                                    isConfigureModel === false
                                                        ? "Необязательно"
                                                        : undefined
                                                }
                                                isClearable={true}
                                                isDisabled={
                                                    !activeSeries?.value
                                                }
                                                onChange={(e) =>
                                                    setActiveModel(
                                                        e as typeof activeModel
                                                    )
                                                }
                                                dataUrl="model"
                                                params={{
                                                    series: activeSeries?.value,
                                                    itemsGroups:
                                                        activeGroup?.value,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleApplyActiveParams}
                                            disabled={isConfigureModel === null}
                                        >
                                            Сконфигурировать{" "}
                                            {isConfigureModel !== null &&
                                                (isConfigureModel
                                                    ? "к модели"
                                                    : "к серии")}
                                        </button>
                                        <button
                                            className="btn btn-primary ms-3"
                                            onClick={() =>
                                                setActiveParams(undefined)
                                            }
                                        >
                                            Сбросить
                                        </button>
                                    </div>
                                </div>

                                {activeParams && (
                                    <PicturesGeneratorContainersWrapper
                                        params={activeParams}
                                        filledAttributes={filledAttributes}
                                        setFilledAttributes={
                                            setFilledAttributes
                                        }
                                        changeIsInterior={(val) =>
                                            setIsInterior(val)
                                        }
                                        isInterior={isInterior}
                                    />
                                )}
                            </div>

                            <div
                                style={{
                                    opacity: pictureLink === "" ? 0 : 1,
                                    alignSelf: "stretch",
                                }}
                                className="control-view__info"
                                id="pictures-index-view-container"
                            >
                                <Sticky>
                                    <img
                                        src={pictureLink}
                                        style={{
                                            width: "100%",
                                            height: "auto",
                                        }}
                                        alt="img"
                                    />
                                </Sticky>

                                {/*<textarea*/}
                                {/*    rows={5}*/}
                                {/*    readOnly*/}
                                {/*    value={pictureLink}*/}
                                {/*    style={{ display: "block", width: "100%" }}*/}
                                {/*></textarea>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
