import { Dayjs } from "dayjs";
import { getDateString } from "utils/date";

export const stringFormatter = {
  count: (one: string, couple: string, five: string) => (n: number) => {
    const lastDigit = n % 10;
    const isTeen = Math.floor(n / 10) % 10 === 1;

    let string = five; // 5 и более
    if (!isTeen) {
      if (lastDigit === 1) {
        string = one; // Для 1, 21, 31 и т.д.
      } else if (lastDigit >= 2 && lastDigit <= 4) {
        string = couple; // Для 2, 3, 4, 22, 23, 24 и т.д.
      }
    }

    return `${n} ${string}`;
  },

  dateForRequest: (value: string | Dayjs) => {
    return getDateString(value, "YYYY-MM-DDTHH:mm:ss");
  },

  date: (value: string) => {
    return getDateString(value, "DD-MM-YYYY");
  },

  time: (value: string) => {
    return getDateString(value, "DD-MM-YYYY в HH:mm");
  },
};
